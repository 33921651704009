<app-bubble-dropdown appendTo=".d-flex.flex-column.position-relative.h-100"
    bindLabel="context.display{{ language === 'en' ? '_en' : '' }}"
    bindValue="value"
    [defaultImage]="'assets/images/default-dept.png'"
    [invalid]="invalid"
    [clearable]="clearable"
    [invalidItems]="invalidItems"
    [class.selected-all]="isSelectedAll"
    [isOpen]="isOpen"
    [items]="departmentChoices"
    [loading]="isDeptFetching"
    [showImage]="true"
    [clearable]="clearable"
    [showSearchInDropdown]="showSearchInDropdown"
    [showSelectedInBox]="showSelectedInBox"
    [ngModel]="isDeptFetching ? [] : selectedDepartment"
    [placeholder]="placeholder"
    (ngModelChange)="onDepartmentDropdownChange($event)">
  <ng-template #choiceLabelTemp
    let-item="item">
    <div class="h-100">
      <label class="d-block fw-normal text-truncate w-100 mb-0"
          [title]="item.context?.['display' + (language === 'en' ? '_en' : '')]">
        {{ item.context?.['display' + (language === 'en' ? '_en' : '')] }}
      </label>
      <label *ngIf="item.context?.id"
          class="d-block text-gray-neutrals text-truncate fs-l0 fs-md-l1 fs-lg-l5 w-100 mb-0">
        ({{ item.context?.member_count }} {{ 'COMMON.UNITS.People' | translate }})
      </label>
    </div>
  </ng-template>
  <ng-template #selectedChoiceImgTemp
      let-item="item">
    <app-profile-display
        [size]="24"
        [person]="item.context"
        [defaultPhoto]="'assets/images/default-dept.png'"
    ></app-profile-display>
  </ng-template>
</app-bubble-dropdown>